export default class LocalStorageService {
	public static separator = ",";
	public static SIDE_NAV_PINNED_NETWORKS = "SIDE_NAV_PINNED_NETWORKS";

	public static getValue(key: string): string | null {
		return localStorage.getItem(key);
	}

	public static setValue(key: string, value: string): void {
		localStorage.setItem(key, value);
	}

	public static appendValueIfNotExist(key: string, value: string): void {
		const data = this.getValue(key);
		if (!data || data === "") {
			this.setValue(key, value);
			return;
		}

		const array = data.split(LocalStorageService.separator);
		if (array.filter((a) => a === value).length === 0) {
			array.push(value);
		}

		this.setValue(key, array.join(LocalStorageService.separator));
	}

	public static removeValueIfNotExist(key: string, value: string): void {
		const data = this.getValue(key);
		if (!data || data === "") {
			return;
		}

		const array = data.split(LocalStorageService.separator);
		this.setValue(
			key,
			array
				.filter((a) => a !== value)
				.join(LocalStorageService.separator),
		);
	}

	public static hasValue(key: string, value: string): boolean {
		const data = this.getValue(key);
		if (!data || data === "") {
			return false;
		}

		const array = data.split(LocalStorageService.separator);
		return array.includes(value);
	}
}
