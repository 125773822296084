import React from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { NavigationItem } from "./NavigationItem";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import MuiDrawer from "@mui/material/Drawer";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import {
	InputAdornment,
	Link,
	ListItemButton,
	Stack,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";

import CookieConsent from "react-cookie-consent";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import DescriptionIcon from "@mui/icons-material/Description";
import NestedMenuList, {
	checkPath,
	hasSubMenuItems,
	getAuthenticatedMenuItems,
} from "./NestedMenuItem";
import { navigationList } from "./NavigationConfiguration";
import LocalStorageService from "../Common/Service/LocalStorageService";
import SearchIcon from "@mui/icons-material/Search";

const drawerWidth = 220;

type SideNavigationProp = {
	open: boolean;
};

const StyledTypography = styled(Typography)(() => ({
	textTransform: "none",
	paddingTop: "5px",
	paddingBottom: "5px",
	letterSpacing: "1px",
	fontWeight: "400",
	component: "span",
}));

const StyledDiv = styled("div")(({ theme }) => ({
	flexGrow: 1,
	overflowY: "auto",
	overflowX: "hidden",
	"&::-webkit-scrollbar": {
		width: "0.4em",
	},
	"&::-webkit-scrollbar-track": {
		boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
		webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
	},
	"&::-webkit-scrollbar-thumb": {
		backgroundColor: theme.palette.primary.light,
	},
}));

const StyledList = styled(List)(() => ({
	maxWidth: 360,
	width: "100%",
	"& ul": { padding: 0 },
}));

const StyledLink = styled(Link)(() => ({
	marginBottom: "5px",
	marginTop: "5px",
}));

const StyledSelectedTypography = styled(StyledTypography)(({ theme }) => ({
	color: theme.palette.primary.main,
	letterSpacing: "1px",
	fontWeight: "500",
}));

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: "hidden",
	width: `calc(${theme.spacing(5)} + 1px)`,
	[theme.breakpoints.up("sm")]: {
		width: `calc(${theme.spacing(7)} + 1px)`,
	},
});

const Drawer = styled(MuiDrawer, {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	shouldForwardProp: (prop: any) => prop !== "open",
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	...(open && {
		...openedMixin(theme),
		"& .MuiDrawer-paper": openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		"& .MuiDrawer-paper": closedMixin(theme),
	}),
}));

function SideNavigation(prop: SideNavigationProp): JSX.Element {
	const { isAuthenticated } = useAuth0();
	const authenticatedMenuItems = getAuthenticatedMenuItems(
		isAuthenticated,
		navigationList,
	);
	const [items, setItems] = React.useState(
		getNavigationItems(authenticatedMenuItems),
	);
	const { pathname: pathName } = useLocation();
	const theme = useTheme();
	const navigate = useNavigate();

	const gotoPage = (item: NavigationItem) => {
		navigate(item.path);
	};

	const onPinedChange = () => {
		const finalNavigationList = getNavigationItems(items);
		setItems(finalNavigationList);
	};

	function getNavigationItems(array: NavigationItem[]): NavigationItem[] {
		const pinned: NavigationItem[] = [];
		const notPinned: NavigationItem[] = [];
		authenticatedMenuItems.forEach((authItem) => {
			if (array.includes(authItem)) {
				if (
					LocalStorageService.hasValue(
						LocalStorageService.SIDE_NAV_PINNED_NETWORKS,
						authItem.title,
					)
				) {
					pinned.push(authItem);
				} else {
					notPinned.push(authItem);
				}
			}
		});

		return [...pinned, ...notPinned];
	}

	const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();
		event.persist();
		const searchTerm = event.target.value;
		const finalNavigationList = getNavigationItems(authenticatedMenuItems);
		if (!searchTerm && searchTerm !== "") {
			setItems(finalNavigationList);
		} else {
			setItems(
				finalNavigationList.filter((n) =>
					n.title.toLowerCase().includes(searchTerm.toLowerCase()),
				),
			);
		}
	};

	return (
		<Drawer open={prop.open} variant="permanent">
			<Box
				style={{
					display: "flex",
					flexDirection: "column",
					height: "100%",
				}}
			>
				<StyledDiv>
					<Toolbar />
					<StyledList>
						{prop.open ? (
							<Box
								sx={{
									marginLeft: "10px",
									marginRight: "10px",
								}}
							>
								<TextField
									sx={{ marginTop: "0px" }}
									id="nav-jumpToBlock"
									label={"Search Blockchain"}
									size="small"
									margin="dense"
									onChange={onChange}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<SearchIcon color="primary" />
											</InputAdornment>
										),
									}}
								/>
							</Box>
						) : (
							""
						)}

						{items.map((item: NavigationItem) =>
							hasSubMenuItems(isAuthenticated, item.subItems) ? (
								<NestedMenuList
									key={item.title + "nested-item" + item.path}
									item={item}
									pinned={LocalStorageService.hasValue(
										LocalStorageService.SIDE_NAV_PINNED_NETWORKS,
										item.title,
									)}
									isSideNavOpen={prop.open}
									isAuthenticated={isAuthenticated}
									currentPath={pathName}
									gotoPage={(subItem) => gotoPage(subItem)}
									pinedChanged={() => onPinedChange()}
								></NestedMenuList>
							) : (
								<ListItemButton
									selected={checkPath(item, pathName)}
									key={"list-button" + item.path}
									onClick={() => gotoPage(item)}
								>
									<ListItemIcon
										key={"list-icon" + item.path}
										title={item.title}
									>
										<Box
											style={{
												color:
													item.path === pathName
														? theme.palette.primary
																.main
														: "",
											}}
										>
											<Tooltip title={item.path}>
												{item.icon}
											</Tooltip>
										</Box>
									</ListItemIcon>
									{item.path === pathName ? (
										<StyledSelectedTypography variant="button">
											{item.title}
										</StyledSelectedTypography>
									) : (
										<StyledTypography variant="button">
											{item.title}
										</StyledTypography>
									)}
								</ListItemButton>
							),
						)}
					</StyledList>
				</StyledDiv>
				<div>
					{prop.open ? (
						<Box
							sx={{
								height: "80px",
								alignItems: "center",
								justifyContent: "center",
								width: "100%",
								padding: "5px",
								backgroundColor: theme.palette.primary.light,
								color: theme.palette.primary.dark,
							}}
						>
							<Stack>
								<Link
									style={{
										fontWeight: "500",
										letterSpacing: "1px",
										margin: "5px",
									}}
									component="button"
									variant="body2"
									onClick={() => {
										navigate("aboutus");
									}}
								>
									Impressum
								</Link>
								<Stack
									spacing={1}
									direction={"row"}
									alignItems="center"
									justifyContent="center"
								>
									<StyledLink
										href="https://www.linkedin.com/company/atalma"
										target="_blank"
									>
										<LinkedInIcon />
									</StyledLink>
									<StyledLink
										href="https://twitter.com/atalma_io"
										target="_blank"
									>
										<TwitterIcon />
									</StyledLink>
									<StyledLink
										href="https://atalma.io"
										target="_blank"
									>
										<DescriptionIcon />
									</StyledLink>
								</Stack>
							</Stack>
						</Box>
					) : (
						""
					)}
				</div>
			</Box>

			<CookieConsent
				location="bottom"
				buttonText="Accept"
				style={{
					background: theme.palette.primary.dark,
					color: theme.palette.primary.light,
					fontWeight: "bold",
				}}
				buttonStyle={{
					color: theme.palette.primary.dark,
					fontSize: "13px",
					fontWeight: "bold",
				}}
				expires={150}
			>
				This website uses cookies to enhance the user experience.
			</CookieConsent>
		</Drawer>
	);
}

export default SideNavigation;
