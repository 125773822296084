import { NetworkConfig, ApiConfig } from "../Common/Models/NetworkConfig";

// Celo Rivera APIs with authorization keyed
// to the production Auth0 tenant
//

const MAINNET_CELO_RIVERA_HOST: ApiConfig = {
	host: "https://api.rivera-celo.atalma.io",
	basePath: "/mainnet",
};

const BAKLAVA_CELO_RIVERA_HOST: ApiConfig = {
	host: "https://api.rivera-celo.atalma.io",
	basePath: "/baklava",
};

const NAMADA_RIVERA_HOST: ApiConfig = {
	host: "",
	basePath: "/baklava",
};

const NYM_RIVERA_HOST: ApiConfig = {
	host: "https://api.rivera-comet.atalma.io",
	basePath: "/nym-mainnet",
};

// Celo Rivera APIs with authorization keyed
// to the development Auth0 tenant
// (use this for localhost development)
//

const MAINNET_DEV_CELO_RIVERA_HOST: ApiConfig = {
	host: "https://api.rivera-celo.atalma.io",
	basePath: "/mainnet",
};

const BAKLAVA_DEV_CELO_RIVERA_HOST: ApiConfig = {
	host: "https://api.rivera-celo.atalma.io",
	basePath: "/baklava",
};

let mainnetApiConfig: ApiConfig = MAINNET_CELO_RIVERA_HOST;
let baklavaApiConfig: ApiConfig = BAKLAVA_CELO_RIVERA_HOST;
let namadaApiConfig: ApiConfig = NAMADA_RIVERA_HOST;
let nymApiConfig: ApiConfig = NYM_RIVERA_HOST;

if (window.location.hostname === "localhost") {
	console.log("Switching to staging API config");
	mainnetApiConfig = MAINNET_DEV_CELO_RIVERA_HOST;
	baklavaApiConfig = BAKLAVA_DEV_CELO_RIVERA_HOST;
	namadaApiConfig = NAMADA_RIVERA_HOST;
	nymApiConfig = NYM_RIVERA_HOST;
} else if (window.location.hostname === "dev.vido.atalma.io") {
	console.log("Switching to staging API config");
	mainnetApiConfig = MAINNET_DEV_CELO_RIVERA_HOST;
	baklavaApiConfig = BAKLAVA_DEV_CELO_RIVERA_HOST;
	namadaApiConfig = NAMADA_RIVERA_HOST;
	nymApiConfig = NYM_RIVERA_HOST;
}

const NETWORK_CONFIG: NetworkConfig[] = [
	{
		network: "baklava",
		remoteNode: "https://baklava-forno.celo-testnet.org",
		blockExplorer: "https://baklava-blockscout.celo-testnet.org/",
		apiAudience: "https://api.rivera-celo.atalma.io",
		signedBlocksConfig: {
			api: baklavaApiConfig,
			firstBlock: 17280,
			paginationListLength: 12,
			defaultViewSize: 100,
		},
		attestationConfig: {
			api: baklavaApiConfig,
			defaultViewSize: 100,
		},
		metricsConfig: {
			api: baklavaApiConfig,
		},
		subscriptionConfig: {
			api: baklavaApiConfig,
		},
	},

	{
		network: "mainnet",
		remoteNode: "https://forno.celo.org",
		blockExplorer: "https://explorer.celo.org/",
		apiAudience: "https://api.rivera-celo.atalma.io",
		signedBlocksConfig: {
			api: mainnetApiConfig,
			firstBlock: 17280,
			paginationListLength: 12,
			defaultViewSize: 100,
		},
		attestationConfig: {
			api: mainnetApiConfig,
			defaultViewSize: 100,
		},
		metricsConfig: {
			api: mainnetApiConfig,
		},
		subscriptionConfig: {
			api: mainnetApiConfig,
		},
	},
	{
		network: "namada",
		remoteNode: "https://forno.celo.org",
		blockExplorer: "https://explorer.celo.org/",
		apiAudience: "https://api.vido.vladiatorlabs.io",
		signedBlocksConfig: {
			api: namadaApiConfig,
			firstBlock: 2,
			paginationListLength: 12,
			defaultViewSize: 100,
		},
		attestationConfig: {
			api: namadaApiConfig,
			defaultViewSize: 100,
		},
		metricsConfig: {
			api: namadaApiConfig,
		},
		subscriptionConfig: {
			api: namadaApiConfig,
		},
	},
	{
		network: "nym",
		remoteNode: "https://rpc.nymtech.net:443",
		blockExplorer: "https://nym.explorers.guru/validator/",
		apiAudience: "https://api.rivera-celo.atalma.io",
		signedBlocksConfig: {
			api: nymApiConfig,
			firstBlock: 2,
			paginationListLength: 12,
			defaultViewSize: 100,
		},
		attestationConfig: {
			api: nymApiConfig,
			defaultViewSize: 100,
		},
		metricsConfig: {
			api: nymApiConfig,
		},
		subscriptionConfig: {
			api: nymApiConfig,
		},
	},
];

export const validatorGroupLink =
	"https://explorer.celo.org/address/0x70FC0b021dFdBb9A106D1Ed8F35f59D3f23eCb7B/celo";
export const validatorGroup = "0x70FC0b021dFdBb9A106D1Ed8F35f59D3f23eCb7B";

export function getConfigForNetwork(network: string): NetworkConfig {
	const config: NetworkConfig = NETWORK_CONFIG.filter(
		(item) => item.network.toLowerCase() === network.toLowerCase(),
	)[0];
	return config;
}
