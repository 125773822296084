import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import {
	ExpandLess,
	ExpandMore,
	PushPin,
	PushPinOutlined,
} from "@mui/icons-material";
import { Box, IconButton, ListItemIcon } from "@mui/material";
import { NavigationItem } from "./NavigationItem";
import LocalStorageService from "../Common/Service/LocalStorageService";

export function checkPath(item: NavigationItem, path: string): boolean {
	if (!path || !item || !item.path) {
		return false;
	}
	const rawPath = path.replace("/", "");
	const itemPathName = item.path.replace("/", "");
	return rawPath.includes(itemPathName);
}

export function hasSubMenuItems(
	isAuthenticated: boolean,
	subItems?: NavigationItem[],
): boolean {
	if (!subItems || subItems.length < 1) {
		return false;
	}
	const authItems = subItems.filter(
		(n) => isAuthenticated || !n.requireAuthentication,
	);
	return authItems.length > 0;
}

export function getAuthenticatedMenuItems(
	isAuthenticated: boolean,
	items?: NavigationItem[],
): NavigationItem[] {
	const array = items.filter(
		(n) => isAuthenticated || !n.requireAuthentication,
	);

	return array;
}

type NestedMenuListProp = {
	item: NavigationItem;
	currentPath: string;
	isAuthenticated: boolean;
	isSideNavOpen: boolean;
	pinned: boolean;
	gotoPage: (item: NavigationItem) => void;
	pinedChanged: () => void;
};

export default function NestedMenuList(props: NestedMenuListProp): JSX.Element {
	const [open, setOpen] = React.useState(true);
	const [pined, setPined] = React.useState(props.pinned);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault();
		event.stopPropagation();
		setOpen(!open);
	};

	const handlePin = (
		event: React.MouseEvent<HTMLElement>,
		network: string,
	) => {
		event.preventDefault();
		event.nativeEvent.stopPropagation();
		event.stopPropagation();
		if (!pined) {
			LocalStorageService.appendValueIfNotExist(
				LocalStorageService.SIDE_NAV_PINNED_NETWORKS,
				network,
			);
		} else {
			LocalStorageService.removeValueIfNotExist(
				LocalStorageService.SIDE_NAV_PINNED_NETWORKS,
				network,
			);
		}
		setPined(!pined);
		props.pinedChanged();
	};

	return (
		<Box>
			<ListItemButton
				disableRipple
				dense={true}
				onClick={(e) => handleClick(e)}
			>
				<ListItemIcon style={{ minWidth: "40px" }}>
					{props.item.icon}
				</ListItemIcon>
				<ListItemText primary={props.item.title} />
				<IconButton
					sx={{ p: 0, transform: "rotate(45deg)" }}
					onClick={(e) => handlePin(e, props.item.title)}
				>
					{pined ? (
						<PushPin style={{ fontSize: 18 }} />
					) : (
						<PushPinOutlined style={{ fontSize: 18 }} />
					)}
				</IconButton>
				<IconButton sx={{ p: 0 }} onClick={(e) => handleClick(e)}>
					{open ? (
						<ExpandLess style={{ fontSize: 26 }} />
					) : (
						<ExpandMore style={{ fontSize: 26 }} />
					)}
				</IconButton>
			</ListItemButton>
			<Collapse in={open} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>
					{getAuthenticatedMenuItems(
						props.isAuthenticated,
						props.item.subItems,
					).map(function (menuItem) {
						return (
							<ListItemButton
								dense={true}
								sx={{ pl: props.isSideNavOpen ? 3 : 2 }}
								selected={checkPath(
									menuItem,
									props.currentPath,
								)}
								key={
									"list-button" +
									props.item.title +
									menuItem.title
								}
								onClick={() => props.gotoPage(menuItem)}
							>
								<ListItemIcon
									style={{ minWidth: "40px" }}
									key={
										"list-icon" +
										props.item.title +
										menuItem.title
									}
								>
									{menuItem.icon}
								</ListItemIcon>
								<ListItemText
									key={
										"list-text" +
										props.item.title +
										menuItem.title
									}
									primary={menuItem.title}
								/>
							</ListItemButton>
						);
					})}
				</List>
			</Collapse>
		</Box>
	);
}
